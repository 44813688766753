import React, { useEffect, useMemo } from 'react';
import * as classes from './no-vacancy-advice.module.scss';
import { HiX } from '@react-icons/all-files/hi/HiX';
import axios from 'axios';
import { FiXCircle } from '@react-icons/all-files/fi/FiXCircle';

interface NoVacancyAdviceProps {
  buttonText?: string;
  buttonUrl?: string;
  workFieldName?: string;
  positionName?: string;
  clearFilters?: boolean;
  city?: string;
}

export default function NoVacancyAdvice(props: NoVacancyAdviceProps) {
  const {
    buttonText,
    buttonUrl,
    workFieldName,
    positionName,
    clearFilters,
    city,
  } = props;
  const [showModal, setShowModal] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [email, setEmail] = React.useState('');

  useEffect(() => {
    const storage = localStorage.getItem('email-checkbox');
    if (storage) {
      setChecked(JSON.parse(storage));
    }
  }, []);

  const handleCheck = () => {
    if (checked) {
      localStorage.removeItem('email-checkbox');
      setShowModal(false);
      setChecked(false);
      return;
    }

    localStorage.setItem('email-checkbox', JSON.stringify(true));
    setChecked(true);
    setShowModal(true);
  };

  const handleConfirm = async () => {
    const tags = [positionName, workFieldName, city].filter(tag => tag);
    setIsLoading(true);

    await axios
      .post(
        'https://mautic.vendoor.me/api/contacts/new',
        {
          email,
          tags: tags,
        },
        {
          headers: {
            Authorization: `Basic dmFnYXMudmVuZG9vcjpBTUNhaDVaVmsmVzlYJFVBJWh1OFBLQ1JFSlV5NGs4`,
          },
        },
      )
      .then(
        ({
          data: {
            contact: { id },
          },
        }) => {
          addContactToSegment(id, 14).then(() => {
            setIsLoading(false);
            setShowModal(false);
          });
        },
      )
      .catch(() => {
        setIsLoading(false);
        setShowModal(false);
      });
  };

  const addContactToSegment = async (contactId: number, segmentId: number) => {
    await axios.post(
      `https://mautic.vendoor.me/api/segments/${segmentId}/contact/${contactId}/add`,
      {},
      {
        headers: {
          Authorization: `Basic dmFnYXMudmVuZG9vcjpBTUNhaDVaVmsmVzlYJFVBJWh1OFBLQ1JFSlV5NGs4`,
        },
      },
    );
  };

  const isButtonDisabled = useMemo(() => {
    if (isLoading) return true;

    if (!email) return true;

    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!emailRegex.test(email)) return true;

    return false;
  }, [isLoading, email]);

  return (
    <>
      <div className={classes.container}>
        <div>
          <div className={classes.title}>Não há mais vagas</div>
          <div className={classes.description}>
            Mude sua busca para ver mais vagas
          </div>
        </div>

        {clearFilters && (
          <div className={classes.noMoreVacanciesButtons}>
            <a href="/vagas/">
              <FiXCircle size={15} color="#e53e3e" />
              Limpar filtros
            </a>
          </div>
        )}

        <div>
          <div className={classes.title}>Receber alerta de vagas?</div>

          <label className="inline-flex items-center cursor-pointer gap-1.5 ">
            <input
              type="checkbox"
              checked={checked}
              className="sr-only peer"
              onChange={() => {
                handleCheck();
              }}
            />
            <div className="relative w-9 h-4 bg-[#718096] peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-3.5 rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[1px] after:start-[1px] after:bg-white after:rounded-full after:h-3.5 after:w-5 after:transition-all peer-checked:bg-[#27BE6B]"></div>
            <span className="text-slate-500 text-[10px] font-bold leading-3">
              Desejo receber novas vagas em meu e-mail
            </span>
          </label>
        </div>

        {buttonUrl && buttonText && <a href={buttonUrl}>{buttonText}</a>}
      </div>

      {showModal && (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className={classes.modal}>
              <div className={classes.modalHeader}>
                <div className={classes.modalTitle}>
                  Insira seu email para receber vagas
                </div>

                <button
                  onClick={() => {
                    setShowModal(false);
                  }}
                >
                  <HiX size="26px" />
                </button>
              </div>
              <div className={classes.description}>
                Você apenas receberá alertas para a busca atual.
              </div>

              <div className={classes.advice}>
                Vagas de <b>Assistente Administrativo em Goiânia</b>
              </div>

              <input
                className={classes.input}
                type="email"
                placeholder="Insira seu melhor email"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />

              <button
                className={classes.button}
                onClick={handleConfirm}
                disabled={isButtonDisabled}
              >
                {isLoading ? 'Confirmando...' : 'Confirmar'}
              </button>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      )}
    </>
  );
}
