// extracted by mini-css-extract-plugin
export var advice = "no-vacancy-advice-module--advice--b2f44";
export var button = "no-vacancy-advice-module--button--8dfa5";
export var container = "no-vacancy-advice-module--container--14b94";
export var description = "no-vacancy-advice-module--description--3201d";
export var input = "no-vacancy-advice-module--input--9d605";
export var modal = "no-vacancy-advice-module--modal--b1522";
export var modalHeader = "no-vacancy-advice-module--modalHeader--32d91";
export var modalTitle = "no-vacancy-advice-module--modalTitle--6a1d6";
export var noMoreVacanciesButtons = "no-vacancy-advice-module--noMoreVacanciesButtons--f280b";
export var title = "no-vacancy-advice-module--title--2358c";