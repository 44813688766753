import React, { useMemo } from 'react';
import { PaginationMeta } from '../../sdk/entities/PaginationMeta';
import { FiArrowRightCircle } from '@react-icons/all-files/fi/FiArrowRightCircle';
import { FiArrowLeftCircle } from '@react-icons/all-files/fi/FiArrowLeftCircle';

export interface PaginationProps {
  meta: PaginationMeta;
  handlePageChange?: (page: number) => void;
}

const Pagination = ({ meta, handlePageChange }: PaginationProps) => {
  const search = new URLSearchParams(window.location.search);
  const pathname = window.location.pathname;

  const pages = useMemo(() => {
    return Array.from({ length: meta.totalPages }).map((_, index) => {
      search.set('page', String(index + 1));

      return {
        page: index + 1,
        url: `${pathname}?${search.toString()}`,
        active: index + 1 === meta.currentPage,
      };
    });
  }, [meta]);

  const currentPage = meta.currentPage;
  const numPagesToShow = 5;

  const getPageRange = () => {
    let startPage = Math.max(1, currentPage - Math.floor(numPagesToShow / 2));
    let endPage = Math.min(meta.totalPages, startPage + numPagesToShow - 1);
    startPage = Math.max(1, endPage - numPagesToShow + 1);
    return Array.from(
      { length: endPage - startPage + 1 },
      (_, i) => startPage + i,
    );
  };

  const pagesToShow = getPageRange();

  if (meta.totalPages <= 1) {
    return null;
  }

  return (
    <div className="flex items-center justify-end mt-6 gap-1">
      {meta.totalPages > 1 && (
        <a
          href={
            handlePageChange
              ? undefined
              : pages[currentPage - 2]
                ? pages[currentPage - 2].url
                : undefined
          }
          className={`
            h-8 w-8 bg-black rounded flex items-center justify-center cursor-pointer
            ${currentPage === 1 ? 'opacity-15' : ''}
          `}
          onClick={
            handlePageChange
              ? () => handlePageChange(currentPage - 1)
              : undefined
          }
        >
          <FiArrowLeftCircle color="#FFFFFF" size="16px" />
        </a>
      )}
      {pages.map(item => {
        if (!pagesToShow.some(page => page === item.page)) {
          return;
        }
        return (
          <a
            href={handlePageChange ? undefined : item.url}
            key={item.page}
            className={`
                h-8 w-8 bg-[#E4E4E4] rounded flex items-center justify-center text-[13px] font-bold cursor-pointer
                ${item.active ? 'bg-[#718096] text-white' : 'text-black'}
              `}
            onClick={
              handlePageChange ? () => handlePageChange(item.page) : undefined
            }
          >
            {item.page}
          </a>
        );
      })}
      {meta.totalPages > 1 && (
        <a
          href={
            handlePageChange
              ? undefined
              : pages[currentPage]
                ? pages[currentPage].url
                : pages[currentPage - 1].url
          }
          className={`
            h-8 w-8 bg-black rounded flex items-center justify-center cursor-pointer
            ${currentPage === meta.totalPages ? 'opacity-15' : undefined}
          `}
          onClick={
            handlePageChange
              ? () => handlePageChange(currentPage + 1)
              : undefined
          }
        >
          <FiArrowRightCircle size="16px" color="#FFFFFF" />
        </a>
      )}
    </div>
  );
};

export default Pagination;
