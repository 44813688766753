import React, { FC, SelectHTMLAttributes } from 'react';

export interface SelectDataProps
  extends SelectHTMLAttributes<HTMLSelectElement> {
  disableEmpty?: boolean;
  emptyText?: string;
  error?: string;
  data: {
    label: string;
    value: string;
  }[];
}

const Select: FC<SelectDataProps> = props => {
  const {
    disableEmpty = true,
    emptyText,
    error,
    className,
    data,
    ...rest
  } = props;

  return (
    <>
      <select aria-label={emptyText} {...rest}>
        {emptyText && (
          <option value="" disabled={disableEmpty}>
            {emptyText}
          </option>
        )}
        {data.map(item => (
          <option value={item.value} key={item.value}>
            {item.label}
          </option>
        ))}
      </select>
    </>
  );
};

export default Select;
