// extracted by mini-css-extract-plugin
export var companyContent = "company-module--companyContent--a3b5c";
export var companyLogo = "company-module--companyLogo--36e02";
export var content = "company-module--content--dde84";
export var description = "company-module--description--62a23";
export var filters = "company-module--filters--55607";
export var footer = "company-module--footer--0a5fb";
export var header = "company-module--header--409ff";
export var input = "company-module--input--36682";
export var label = "company-module--label--f8e06";
export var links = "company-module--links--9f78d";
export var select = "company-module--select--202ce";
export var title = "company-module--title--4de39";
export var vacancies = "company-module--vacancies--c9c86";
export var vacanciesContainer = "company-module--vacanciesContainer--2fc18";